<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Peta Rencana
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-if="useKrisna"
          v-b-tooltip.hover.top="'Nama Program sesuai dengan Peta Rencana'"
          label="Nama Program (Peta Rencana)"
          label-for="vi-program_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Program (Peta Rencana)"
            rules="required"
          >
            <v-select
              id="vi-program_get"
              v-model="program_get"
              required
              name="nama_program_get"
              :options="saktiProgram"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetKegiatan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Program sesuai dengan Peta Rencana'"
          label="Nama Program (Peta Rencana)"
          label-for="vi-nama_program_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Program (Peta Rencana)"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_program_get"
                v-model="nama_program_get"
                required
                name="nama_program_get"
                placeholder="Nama Program sesuai dengan Peta Rencana Lengkap Kode dan Nama"
                :autofocus="(fokusRo === 'kro') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  v-if="nama_program_get"
                  variant="outline-secondary"
                  @click="kroReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-info">Pastikan penulisan Nama Program telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-if="useKrisna"
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Peta Rencana'"
          label="Nama Kegiatan (Peta Rencana)"
          label-for="vi-kegiatan_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan (Peta Rencana)"
            rules="required"
          >
            <v-select
              id="vi-kegiatan_get"
              v-model="kegiatan_get"
              required
              name="kegiatan_get"
              :options="saktiKegiatan"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetItem()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Peta Rencana'"
          label="Nama Kegiatan (Peta Rencana)"
          label-for="vi-nama_kegiatan_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan (Peta Rencana)"
            rules="required"
          >
            <b-form-input
              id="vi-nama_kegiatan_get"
              v-model="nama_kegiatan_get"
              required
              name="nama_kegiatan_get"
              placeholder="Nama Kegiatan sesuai dengan Peta Rencana Lengkap Kode dan Nama"
            />
            <small class="text-info">Pastikan penulisan Nama Kegiatan telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, VBTooltip,
  // BLink, BAlert, BFormFile, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    // BAlert,
    BRow,
    BCol,
    // BLink,
    // BFormFile,
    BFormInput,
    BFormGroup,
    // BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      year_budget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      sub_title: '',
      jenis_pengadaan_id: '',
      clearance_id: '',
      data_utama_id: '',
      dataUtama: {},
      program_get: '',
      nama_program_get: '',
      kegiatan_get: '',
      nama_kegiatan_get: '',
      useKrisna: true,
      saktiLoad: false,
      saktiFailed: false,
      saktiFailedMsg: '',
      saktiFailedLink: false,
      saktiToken: '',
      saktiUnit: [],
      saktiProgram: [],
      saktiKegiatan: [],
      saktiItem: [],
      saktiKey: String('XE8flR+dlBc94t1QU1A1zg428Z7QWp9Jyo4o9DV4xc9OSBPq83an44q1qvhDzWrImtZpN9sfWmt7THjLoJS6IA--'),
      krisnaKey: String('b0f68e37-1adf-4d4f-9baa-d64b7ef32a1d'),
    }
  },
  watch: {
    actionStep() {
      this.savePetaRencana()
    },
  },
  mounted() {
    this.jenis_pengadaan_id = Number(this.$route.params.jenis)
    this.getReference()
    // this.data_utama_id = 929
  },
  methods: {
    namaReset() {
      this.nama_kegiatan = ''
      this.nama_kegiatan_sendiri = ''
      this.nama_kode_model_referensi = ''
      this.kode_model_referensi = ''
      this.saktiKro = []
      this.useKrisna = true
      this.saktiGetUnit()
    },
    splpSaktiGetToken() {
      this.saktiLoad = true
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get(`/sakti/token?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.saktiToken = res.data.data.access_token
            store.commit('app/SAKTI_TOKEN', this.saktiToken)
            this.saktiGetUnit()
            // this.checkLocal()
          } else {
            this.saktiFailed = true
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.saktiLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetUnit() {
      this.$kris.get(`/${this.tahun_anggaran}`, {
        // headers: {
        //   Authorization: `Bearer ${this.saktiToken}`,
        // },
        params: {
          table: 't_unit',
          kddept: this.userData.instansi_kddept,
          apikey: this.krisnaKey,
          output_format: 'json',
        },
      })
        .then(res => {
          if (res.data.success === true) {
            this.saktiUnit = []
            const pics = []
            res.data.data.map(value => {
              const pic = value
              pic.label = `${value.kddept}.${value.kdunit}. ${value.nmunit}`
              pics.push(pic)
              return true
            })
            if (pics.length === 0) {
              this.saktiFailed = true
              this.saktiFailedLink = true
              this.useKrisna = false
              this.saktiFailedMsg = 'Data Kegiatan tidak ditemukan di Aplikasi Krisna, hubungi Admin atau isi form pada '
            } else {
              this.saktiUnit = pics
            }
            const lainnya = {
              kdunit: 'other',
              label: 'Unit Kerja lain (Tambah Unit)',
            }
            this.saktiUnit.push(lainnya)
            // this.checkLocal()
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          } else {
            // this.checkLocal()
            this.saktiFailed = true
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.saktiLoad = false
          this.useKrisna = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetKegiatan() {
      if (this.unit_kerja !== null) {
        if (this.unit_kerja !== '' && this.unit_kerja.kdunit !== 'other') {
          this.useKrisna = true
          this.nama_unit_kerja = this.unit_kerja.label
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            table: 't_giat',
            kddept: this.userData.instansi_kddept,
            apikey: this.krisnaKey,
            output_format: 'json',
          }
          if (this.unit_kerja !== '') {
            params.kdunit = this.unit_kerja.kdunit
          }
          this.$kris.get(`/${this.tahun_anggaran}`, {
            // headers: {
            //   Authorization: `Bearer ${this.saktiToken}`,
            // },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiKegiatan = []
                this.nama_kegiatan = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}. ${value.nmgiat}`
                  pics.push(pic)
                  return true
                })
                this.saktiKegiatan = pics
                const lainnya = {
                  kdgiat: 'other',
                  label: 'Kegiatan Lainnya (Tambah Kegiatan Baru)',
                }
                this.saktiKegiatan.push(lainnya)
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useKrisna = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useKrisna = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useKrisna = false
          const pics2 = []
          this.daftarKroTambahan.map(value => {
            const pic = value
            pics2.push(pic)
            return true
          })
          this.saktiKro = pics2

          this.nama_unit_kerja = ''
          this.fokusRo = 'unit'
          this.kode_model_referensi = ''
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        this.useKrisna = true
        this.saktiGetUnit()
        this.unit_kerja = ''
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiUnit = []
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
      }
    },
    saktiGetProgram() {
      if (this.nama_kegiatan !== null) {
        if (this.nama_kegiatan !== '' && this.nama_kegiatan.kdgiat !== 'other') {
          this.useKrisna = true
          this.nama_kegiatan_sendiri = this.nama_kegiatan.label
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            table: 't_output',
            kddept: this.userData.instansi_kddept,
            kdunit: this.unit_kerja.kdunit,
            apikey: this.krisnaKey,
            output_format: 'json',
          }
          if (this.nama_kegiatan !== '') {
            params.kdgiat = this.nama_kegiatan.kdgiat
          }
          this.$kris.get(`/${this.tahun_anggaran}`, {
            // headers: {
            //   Authorization: `Bearer ${this.saktiToken}`,
            // },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiKro = []
                this.kode_model_referensi = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}.${value.kdoutput}. ${value.nmoutput}`
                  pics.push(pic)
                  return true
                })
                this.saktiKro = pics
                const lainnya = {
                  kdunit: 'other',
                  label: 'KRO lainnya (Sesuai Renja)',
                }
                this.saktiKro.push(lainnya)
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useKrisna = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useKrisna = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useKrisna = false
          this.saktiKro = []
          this.fokusRo = 'giat'
          this.kode_model_referensi = ''
          this.nama_kode_model_referensi = ''
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        this.useKrisna = true
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.nama_kode_model_referensi = ''
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetKegiatan()
      }
    },
    saktiGetItem() {
      return true
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            // this.referenceData.ref_pic.unshift(this.daftarPenanggungJawabModel)
            if (res.data.data.ref_pic) {
              const pics = [this.daftarPenanggungJawabModel]
              res.data.data.ref_pic.map(value => {
                const pic = value
                pic.label = `${value.nama} (${value.pic_number})`
                pics.push(pic)
                return true
              })
              this.referenceData.ref_pic = pics
            }

            res.data.data.ref_jenis_pengadaan.map(value => {
              if (value.jenis_pengadaan_id === this.jenis_pengadaan_id) {
                this.master_jenis_pengadaan_id = value
                this.sub_title = `Pengadaan ${value.nama}`
              }
              return true
            })
          }
        })
    },
    savePetaRencana() {
      const metaUtama = {
        instansi_id: this.userData.instansi_id,
        clearance_id: this.clearance_id,
        pic_name: this.pic_name,
        pic_number: this.pic_number,
        tahun_anggaran: this.tahun_anggaran,
        total_anggaran: this.total_anggaran,
        kode_anggaran: this.kode_anggaran,
        prioritas_nasional: this.prioritas_nasional.value,
        jenis_belanja_id: Number(this.master_jenis_belanja_id.jenis_belanja_id),
        jenis_pengadaan_id: Number(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        sumber_dana_id: Number(this.master_sumber_dana_id.sumber_dana_id),
      }
      if (this.useKrisna) {
        metaUtama.nama_kegiatan = (this.nama_kegiatan.label) ? this.nama_kegiatan.label : this.nama_kegiatan
        metaUtama.unit_kerja = (this.unit_kerja.label) ? this.unit_kerja.label : this.unit_kerja
        metaUtama.kode_model_referensi = (this.kode_model_referensi.label) ? this.kode_model_referensi.label : this.kode_model_referensi
        metaUtama.rincian_output = (this.rincian_output.label) ? this.rincian_output.label : this.rincian_output
      } else {
        metaUtama.nama_kegiatan = this.nama_kegiatan_sendiri
        metaUtama.unit_kerja = this.nama_unit_kerja
        metaUtama.kode_model_referensi = this.nama_kode_model_referensi
        metaUtama.rincian_output = this.rincian_output
      }
      if (this.penanggungJawabModel.pic_id !== 'new') {
        metaUtama.pic_id = this.penanggungJawabModel.pic_id
      }
      if (this.data_utama_id) {
        metaUtama.data_utama_id = this.data_utama_id
      }
      if (this.master_sumber_dana_id.sumber_dana_id < 3) {
        metaUtama.is_mou = this.is_mou
      }
      this.$http.post('/clearance/core-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          store.commit('app/TOGGLE_SAKTI', this.saktiItem)
          this.data_utama_id = res.data.data.data_utama_id

          const status = true
          const promise1 = new Promise(resolve => {
            if (this.is_mou) {
              document.getElementById('loading-bg').style.display = 'block'
              const formFile = new FormData()
              formFile.append('clearance_id', this.clearance_id)
              formFile.append('data_utama_id', this.data_utama_id)
              formFile.append('mou_file', this.file1)

              this.$http.post('clearance/core-data/file/upload', formFile, {
                params: {
                  token: localStorage.getItem('userToken'),
                },
              })
                .then(resp => {
                  if (!resp.data.status === 'success') {
                    const dataF = {
                      status: false,
                      msg: resp.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.$emit('step-completed', dataF)
                    resolve(false)
                  }
                  resolve(true)
                })
                .catch(error => {
                  const dataF = {
                    status: false,
                    msg: error.response.data.error,
                  }
                  document.getElementById('loading-bg').style.display = 'none'
                  this.$emit('step-completed', dataF)
                  resolve(false)
                })
            } else {
              resolve(true)
            }
          })

          Promise.all([promise1, status]).then(values => {
            if (values[0] && values[1]) {
              const data = {
                status: true,
                msg: res.data.data,
                sakti: this.saktiItem,
                useKrisna: this.useKrisna,
              }
              document.getElementById('loading-bg').style.display = 'none'
              this.$emit('step-completed', data)
            }
          })
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
