<template>
  <div>
    <b-alert
      v-if="krisnaLoad"
      variant="warning"
      show
    >
      <div class="alert-body">
        <span>Memuat data <b>Krisna</b>, mohon menunggu</span>
      </div>
    </b-alert>
    <b-alert
      v-if="krisnaFailed"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Error Found with <b>Krisna</b>
      </h4>
      <div class="alert-body">
        <span v-if="krisnaFailedMsg != ''">
          {{ krisnaFailedMsg }}
        </span>
        <span v-else>Konektivitas dengan <u>Krisna</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi
          <u>Admin</u></span>
      </div>
    </b-alert>
    <template v-if="!krisnaLoad">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Pilih Data Krisna
          </h5>
        </b-col>
        <b-col
          md="12"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Kriteria Belanja Program sesuai dengan Renja'"
            label="Kriteria Belanja"
            label-for="vi-master_jenis_pengadaan_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Kriteria Belanja"
              rules="required"
            >
              <v-select
                id="vi-master_jenis_pengadaan_id"
                v-model="master_jenis_pengadaan_id"
                required
                name="master_jenis_pengadaan_id"
                :options="referenceData.ref_jenis_pengadaan"
                placeholder="Pilih Salah Satu"
                label="nama"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Nama Unit Kerja Eselon 1'"
            label="Unit Kerja Eselon 1"
            label-for="vi-unit_kerja"
          >
            <validation-provider
              #default="{ errors }"
              name="Unit Kerja Eselon 1"
              rules="required"
            >
              <v-select
                id="vi-unit_kerja"
                v-model="unit_kerja_es1"
                required
                name="unit_kerja1"
                :options="krisnaES1"
                placeholder="Pilih Salah Satu"
                label="label"
                @input="selectES2()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Nama Unit Kerja Eselon 2'"
            label="Unit Kerja Eselon 2"
            label-for="vi-unit_kerja"
          >
            <validation-provider
              #default="{ errors }"
              name="Unit Kerja Eselon 2"
              rules="required"
            >
              <v-select
                id="vi-unit_kerja"
                v-model="unit_kerja_es2"
                required
                name="unit_kerja2"
                :options="listES2"
                placeholder="Pilih Salah Satu"
                label="label"
                @input="selectRO()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Rincian Output sesuai dengan Renja'"
            label="Rincian Output"
            label-for="vi-selected_ro"
          >
            <validation-provider
              #default="{ errors }"
              name="Rincian Output"
              rules="required"
            >
              <v-select
                id="vi-selected_ro"
                v-model="selected_ro"
                required
                name="selected_ro"
                :options="listItem"
                placeholder="Pilih Salah Satu"
                label="label"
                @input="selectKomponen()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Komponen Rincian Output sesuai dengan Renja'"
            label="Item Pengadaan"
            label-for="vi-selected_komponen"
          >
            <validation-provider
              #default="{ errors }"
              name="Item Pengadaan"
              rules="required"
            >
              <v-select
                id="vi-selected_komponen"
                v-model="selected_komponen"
                required
                name="selected_komponen"
                :options="listKomponen"
                placeholder="Pilih Salah Satu"
                label="nama"
                @input="completeDataUtama()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-alert
        v-if="krisnaLoad2"
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>Menyusun Metadata Utama</span>
        </div>
      </b-alert>

    </template>
    <template v-if="krisnaHave">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Metadata Utama {{ sub_title }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Tahun Anggaran Program sesuai dengan Renja'"
            label="Tahun Anggaran"
            label-for="vi-tahun_anggaran"
          >
            <validation-provider
              #default="{ errors }"
              name="Tahun Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-tahun_anggaran"
                v-model="tahun_anggaran"
                type="number"
                required
                name="tahun_anggaran"
                placeholder="Tahun Anggaran"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Nama Penanggung Jawab sesuai dengan Renja'"
            label="Penanggung Jawab"
            label-for="vi-pic_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Penanggung Jawab"
              rules="required"
            >
              <v-select
                id="vi-pic_id"
                v-model="penanggungJawabModel"
                required
                name="pic_id"
                :options="referenceData.ref_pic"
                label="label"
                placeholder="Pilih Salah Satu atau Tambah Baru"
                @input="setContact()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="penanggungJawabModel.pic_id === 'new'">
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Nama Penanggung Jawab sesuai dengan Renja'"
              label="Nama Penanggung Jawab"
              label-for="vi-pic_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Penanggung Jawab"
                rules="required"
              >
                <b-form-input
                  id="vi-pic_name"
                  v-model="pic_name"
                  required
                  name="pic_name"
                  placeholder="Nama Penanggung Jawab"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Nomor Kontak Penanggung Jawab sesuai dengan Renja'"
              label="Nomor Penanggung Jawab"
              label-for="vi-pic_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Nomor Penanggung Jawab"
                rules="required"
              >
                <b-form-input
                  id="vi-pic_number"
                  v-model="pic_number"
                  type="number"
                  required
                  name="pic_number"
                  placeholder="Nomor Penanggung Jawab"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-form-input
          v-model="pic_name"
          :value="penanggungJawabModel.name"
          hidden
          name="pic_name"
        />
        <b-form-input
          v-model="pic_number"
          :value="penanggungJawabModel.number"
          hidden
          name="pic_number"
        />
      </div>
      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="nama_program_sendiri"
            label="Nama Program (Krisna)"
            label-for="vi-nama_program_sendiri"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_program_sendiri"
                v-model="nama_program_sendiri"
                required
                name="nama_program_sendiri"
                placeholder="Nama Program sesuai dengan Krisna Lengkap Kode dan Nama"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="nama_kegiatan_sendiri"
            label="Nama Kegiatan (Krisna)"
            label-for="vi-nama_kegiatan_sendiri"
          >
            <b-form-input
              id="vi-nama_kegiatan_sendiri"
              v-model="nama_kegiatan_sendiri"
              required
              name="nama_kegiatan_sendiri"
              placeholder="Nama Kegiatan sesuai dengan Krisna/Sakti Lengkap Kode dan Nama"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="nama_kode_model_referensi"
            label="Klasifikasi Rincian Output (KRO)"
            label-for="vi-kode_model_referensi"
          >
            <b-input-group>
              <b-form-input
                id="vi-kode_model_referensi"
                v-model="nama_kode_model_referensi"
                required
                name="kode_model_referensi"
                placeholder="Klasifikasi Rincian Output (KRO) Lengkap Kode dan Nama"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="rincian_output"
            label="Rincian Output (RO)"
            label-for="vi-rincian_output"
          >
            <b-form-input
              id="vi-rincian_output"
              v-model="rincian_output"
              required
              name="rincian_output"
              placeholder="Rincian Output (RO) Lengkap Kode dan Nama"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="item_komponen"
            label="Nama Item Pengadaan"
            label-for="vi-item_komponen"
          >
            <b-form-input
              id="vi-item_komponen"
              v-model="item_komponen"
              required
              name="item_komponen"
              placeholder="Nama Item Pengadaan Lengkap Kode dan Nama"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran !== '') ? ': Rp. ' + Number(total_anggaran).toLocaleString() : ''}`"
            label-for="vi-total_anggaran"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran"
                v-model="total_anggaran"
                type="number"
                required
                name="total_anggaran"
                placeholder="Total Anggaran"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Kode Mata Anggaran Program sesuai dengan Renja'"
            label="Kode Mata Anggaran"
            label-for="vi-kode_anggaran"
          >
            <b-form-input
              id="vi-kode_anggaran"
              v-model="kode_anggaran"
              required
              name="kode_anggaran"
              placeholder="Kode Mata Anggaran"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Apakah Program Merupakan Prioritas Nasional?'"
            label="Prioritas Nasional"
            label-for="vi-prioritas_nasional"
          >
            <validation-provider
              #default="{ errors }"
              name="Prioritas Nasional"
              rules="required"
            >
              <v-select
                id="vi-prioritas_nasional"
                v-model="prioritas_nasional"
                required
                name="prioritas_nasional"
                :options="daftarNationalPriority"
                placeholder="Pilih Salah Satu"
                label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Jenis Belanja Program sesuai dengan Renja'"
            label="Jenis Belanja"
            label-for="vi-master_jenis_belanja_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Jenis Belanja"
              rules="required"
            >
              <v-select
                id="vi-master_jenis_belanja_id"
                v-model="master_jenis_belanja_id"
                required
                name="master_jenis_belanja_id"
                :options="referenceData.ref_jenis_belanja"
                placeholder="Pilih Salah Satu"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Sumber Dana Program sesuai dengan Renja'"
            label="Sumber Dana"
            label-for="vi-master_sumber_dana_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Sumber Dana"
              rules="required"
            >
              <v-select
                id="vi-master_sumber_dana_id"
                v-model="master_sumber_dana_id"
                required
                name="master_sumber_dana_id"
                :options="referenceData.ref_sumber_dana"
                placeholder="Pilih Salah Satu"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="master_sumber_dana_id && master_sumber_dana_id.sumber_dana_id < 3">
        <b-col md="6">
          <b-form-group
            v-b-tooltip.hover.top="'Apakah Kegiatan Sudah Memiliki MOU atau Belum'"
            label="Sudah Memiliki MOU?"
            label-for="vi-is_mou"
          >
            <validation-provider
              #default="{ errors }"
              name="Sudah Memiliki MOU?"
              rules="required"
            >
              <b-form-checkbox
                v-model="is_mou"
                switch
                inline
                class="mt-1"
              /> {{ (is_mou) ? 'Sudah' : 'Belum' }}
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="is_mou"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload file Dokumen Penyerta (format .pdf)'"
            label="Dokumen Penyerta (format PDF)"
            label-for="file_mou"
          >
            <validation-provider
              #default="{ errors }"
              name="Dokumen Penyerta"
              rules="required"
            >
              <b-form-file
                ref="file_mou"
                v-model="file1"
                name="file_mou"
                class="mt-1"
                required
                @input="checkFile"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, VBTooltip, BAlert, BFormFile, BFormCheckbox,
  // BLink,
  BInputGroup,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    // BLink,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => { },
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      year_budget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      referenceKrisna: {
        ref_program: [],
        ref_kegiatan: [],
        ref_kro: [],
      },
      sub_title: '',
      jenis_pengadaan_id: '',
      clearance_id: '',
      data_utama_id: '',
      dataUtama: {},
      nama_kode_model_referensi: '',
      nama_kegiatan_sendiri: '',
      nama_program_sendiri: '',
      unit_kerja: '',
      pic_id: '',
      pic_name: '',
      pic_number: '',
      kode_model_referensi: '',
      rincian_output: '',
      item_komponen: '',
      tahun_anggaran: '',
      total_anggaran: '',
      kode_anggaran: '',
      prioritas_nasional: '',
      penanggungJawabModel: '',
      daftarPenanggungJawabModel: {
        pic_id: 'new', label: 'Tambah Data Penanggung Jawab Baru', pic_number: '',
      },
      daftarNationalPriority: [
        { value: '0', text: 'Tidak' },
        { value: '1', text: 'Ya' },
      ],
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      useKrisna: true,
      saktiUnit: [],
      saktiItem: {},
      is_mou: false,
      file1: null,
      file2: [],
      file3: null,
      krisnaKey: String('b0f68e37-1adf-4d4f-9baa-d64b7ef32a1d'),
      krisnaKeyRef: String('7d4891ab-ea23-435c-bbd0-4aa38d5e4140'),
      krisnaHave: false,
      krisnaLoad: true,
      krisnaLoad2: false,
      krisnaFailed: false,
      krisnaFailedMsg: '',
      krisnaData: [],
      krisnaES1: [],
      krisnaES2: [],
      krisnaItem: [],
      listES2: [],
      listItem: [],
      listKomponen: [],
      unit_kerja_es1: '',
      unit_kerja_es2: '',
      selected_ro: '',
      selected_komponen: '',
    }
  },
  watch: {
    actionStep() {
      this.saveDataUtama()
    },
  },
  created() {
    this.clearance_id = this.$route.params.id
    this.jenis_pengadaan_id = Number(this.$route.params.jenis)
    this.getClearance()
  },
  mounted() {
    this.getReference()
    // this.data_utama_id = 929
  },
  methods: {
    selectES2() {
      this.listES2 = this.krisnaES2[this.unit_kerja_es1.id]
    },
    selectRO() {
      this.listItem = this.krisnaItem[this.unit_kerja_es1.id][this.unit_kerja_es2.id]
    },
    selectKomponen() {
      this.listKomponen = this.selected_ro.komponens
      this.kode_anggaran = this.selected_ro.kode

      const kodeItem = this.selected_ro.kode.split('.')

      // eslint-disable-next-line prefer-destructuring
      const namaProgram = kodeItem[0]
      // eslint-disable-next-line prefer-destructuring
      const namaKegiatan = kodeItem[1]
      // eslint-disable-next-line prefer-destructuring
      const namaKro = kodeItem[2]

      this.nama_program_sendiri = this.referenceKrisna.ref_program[namaProgram]
      this.nama_kegiatan_sendiri = this.referenceKrisna.ref_kegiatan[namaKegiatan]
      this.nama_kode_model_referensi = this.referenceKrisna.ref_kro[namaKro]
    },
    completeDataUtama() {
      document.getElementById('loading-bg').style.display = 'block'

      this.krisnaLoad2 = true
      this.rincian_output = `${this.selected_ro.kode} - ${this.selected_ro.nama}`
      this.item_komponen = this.selected_komponen.nama

      const namaItem = this.selected_komponen.nama.split(' - ')
      this.total_anggaran = this.selected_komponen.alokasi * 1000
      // eslint-disable-next-line prefer-destructuring
      this.kode_anggaran = namaItem[0]

      setTimeout(() => {
        document.getElementById('loading-bg').style.display = 'none'
        this.krisnaLoad2 = false
        this.krisnaHave = true
      }, 1500)
    },
    checkFile() {
      let err = ''
      if (this.file1) {
        if (this.file1.size > 100 * 1024 * 1024) {
          err = `${this.file1.name}: Ukuran Dokumen Terlalu Besar`
        } if (this.file1.type !== 'application/pdf') {
          err = `${this.file1.name}: Jenis Dokumen Tidak Sesuai`
        }
      }
      if (err !== '') {
        const dataF = {
          status: false,
          msg: err,
        }
        this.$emit('step-completed', dataF)
      }
    },
    checkLocal() {
      if (localStorage.getItem('dataBaru')) {
        this.data_utama_id = Number(localStorage.getItem('dataBaru'))
        this.getData()
      }
    },
    getClearance() {
      this.$http.get('/clearance', {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.clearance_id,
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const cdata = res.data.data
            this.tahun_anggaran = cdata.year_budget
            this.loadKrisna()
            this.getKrisnaKegiatanReference()
            this.getKrisnaProgramReference()
            this.getKrisnaKroReference()
          }
        })
    },
    loadKrisna() {
      document.getElementById('loading-bg').style.display = 'block'
      this.krisnaLoad = true
      const url = `${process.env.VUE_APP_KRISNA_URL}/renjakl-clearance-tagging/${this.tahun_anggaran}?kddept=${this.userData.instansi_kddept}&apikey=${this.krisnaKey}&output_format=json`

      this.$http.get('/krisna', {
        params: {
          token: localStorage.getItem('userToken'),
          endpoint: url,
        },
      })
        .then(res => {
          // console.log(res.data)
          if (res.data.status === 'success') {
            const allData = res.data.data.data

            this.krisnaData = res.data.data.data
            allData.map(kris => {
              const es1 = kris.unit_organisasi.split(' - ')
              const es2 = kris.unit_pelaksana.split(' - ')
              if (this.krisnaES1.indexOf(es1[0]) === -1) {
                this.krisnaES1.push({
                  id: es1[0],
                  label: kris.unit_organisasi,
                })
              }

              if (this.krisnaES2.indexOf(es1[0]) === -1) {
                this.krisnaES2[es1[0]] = []
              }

              if (this.krisnaES2[es1[0]].indexOf(es2[0]) === -1) {
                this.krisnaES2[es1[0]].push({
                  id: es2[0],
                  label: kris.unit_pelaksana,
                })
              }

              if (this.krisnaItem.indexOf(es1[0]) === -1) {
                this.krisnaItem[es1[0]] = []
              }

              if (this.krisnaItem[es1[0]].indexOf(es2[0]) === -1) {
                this.krisnaItem[es1[0]][es2[0]] = []
              }

              const data = kris
              data.label = `${kris.kode} ${kris.nama}`

              this.krisnaItem[es1[0]][es2[0]].push(data)

              return true
            })
            this.krisnaLoad = false

            document.getElementById('loading-bg').style.display = 'none'
          } else {
            this.krisnaFailed = true
            this.krisnaLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.krisnaFailedMsg = error
          this.krisnaFailed = true
          this.krisnaLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    setContact() {
      this.pic_name = this.penanggungJawabModel.name
      this.pic_number = this.penanggungJawabModel.number
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            // this.referenceData.ref_pic.unshift(this.daftarPenanggungJawabModel)
            if (res.data.data.ref_pic) {
              const pics = [this.daftarPenanggungJawabModel]
              res.data.data.ref_pic.map(value => {
                const pic = value
                pic.label = `${value.nama} (${value.pic_number})`
                pics.push(pic)
                return true
              })
              this.referenceData.ref_pic = pics
            }

            res.data.data.ref_jenis_pengadaan.map(value => {
              if (value.jenis_pengadaan_id === this.jenis_pengadaan_id) {
                this.master_jenis_pengadaan_id = value
                this.sub_title = `Pengadaan ${value.nama}`
              }
              return true
            })
          }
        })
    },
    getKrisnaKegiatanReference() {
      // daftar kegiatan
      const url = `${process.env.VUE_APP_KRISNA_URL}/renjakl-kegiatan/${this.tahun_anggaran}?kodekl=${this.userData.instansi_kddept}&apikey=${this.krisnaKeyRef}&output_format=json`

      this.$http.get('/krisna', {
        params: {
          token: localStorage.getItem('userToken'),
          endpoint: url,
        },
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data.success === true) {
            this.referenceKrisna.ref_kegiatan = []
            res.data.data.data.map(value => {
              this.referenceKrisna.ref_kegiatan[value.kegiatan_kode] = `${value.kegiatan_kode} - ${value.kegiatan_nama}`
              return true
            })
          } else {
            this.krisnaFailed = true
            this.krisnaLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
    },
    getKrisnaProgramReference() {
      // daftar program
      const url = `${process.env.VUE_APP_KRISNA_URL}/renjakl-program/${this.tahun_anggaran}?kodekl=${this.userData.instansi_kddept}&apikey=${this.krisnaKeyRef}&output_format=json`

      this.$http.get('/krisna', {
        params: {
          token: localStorage.getItem('userToken'),
          endpoint: url,
        },
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data.success === true) {
            this.referenceKrisna.ref_program = []
            res.data.data.data.map(value => {
              this.referenceKrisna.ref_program[value.program_kode] = `${value.program_kode} - ${value.program_nama}`
              return true
            })
          } else {
            this.krisnaFailed = true
            this.krisnaLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
    },
    getKrisnaKroReference() {
      // daftar program
      const url = `${process.env.VUE_APP_KRISNA_URL}/renjakl-kro/${this.tahun_anggaran}?kodekl=${this.userData.instansi_kddept}&apikey=${this.krisnaKeyRef}&output_format=json`

      this.$http.get('/krisna', {
        params: {
          token: localStorage.getItem('userToken'),
          endpoint: url,
        },
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data.success === true) {
            this.referenceKrisna.ref_kro = []
            res.data.data.data.map(value => {
              this.referenceKrisna.ref_kro[value.kode] = `${value.kode} - ${value.nama}`
              return true
            })
          } else {
            this.krisnaFailed = true
            this.krisnaLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
    },
    saveDataUtama() {
      if (this.pic_name === '' || this.pic_number === '') {
        const data = {
          status: false,
          msg: 'Data Penanggung Jawab Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        const metaUtama = {
          instansi_id: this.userData.instansi_id,
          clearance_id: this.clearance_id,
          pic_name: this.pic_name,
          pic_number: this.pic_number,
          unit_kerja: `${this.unit_kerja_es1.label} - ${this.unit_kerja_es2.label}`,
          nama_kegiatan: this.nama_kegiatan_sendiri,
          kode_model_referensi: this.nama_kode_model_referensi,
          rincian_output: this.rincian_output,
          total_anggaran: this.total_anggaran,
          kode_anggaran: this.kode_anggaran,
          prioritas_nasional: this.prioritas_nasional.value,
          jenis_belanja_id: Number(this.master_jenis_belanja_id.jenis_belanja_id),
          jenis_pengadaan_id: Number(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
          sumber_dana_id: Number(this.master_sumber_dana_id.sumber_dana_id),
          nama_program: this.nama_program_sendiri,
          item_komponen: this.item_komponen,
        }
        if (this.penanggungJawabModel.pic_id !== 'new') {
          metaUtama.pic_id = this.penanggungJawabModel.pic_id
        }
        if (this.data_utama_id) {
          metaUtama.data_utama_id = this.data_utama_id
        }
        if (this.master_sumber_dana_id.sumber_dana_id < 3) {
          metaUtama.is_mou = this.is_mou
        }
        this.$http.post('/clearance/core-data', metaUtama, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            store.commit('app/TOGGLE_SAKTI', this.saktiItem)
            this.data_utama_id = res.data.data.data_utama_id

            const status = true
            const promise1 = new Promise(resolve => {
              if (this.is_mou) {
                document.getElementById('loading-bg').style.display = 'block'
                const formFile = new FormData()
                formFile.append('clearance_id', this.clearance_id)
                formFile.append('data_utama_id', this.data_utama_id)
                formFile.append('mou_file', this.file1)

                this.$http.post('clearance/core-data/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(resp => {
                    if (!resp.data.status === 'success') {
                      const dataF = {
                        status: false,
                        msg: resp.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.$emit('step-completed', dataF)
                      resolve(false)
                    }
                    resolve(true)
                  })
                  .catch(error => {
                    const dataF = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.$emit('step-completed', dataF)
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, status]).then(values => {
              if (values[0] && values[1]) {
                const data = {
                  status: true,
                  msg: res.data.data,
                  sakti: this.saktiItem,
                  useKrisna: this.useKrisna,
                }
                document.getElementById('loading-bg').style.display = 'none'
                this.$emit('step-completed', data)
              }
            })
          })
          .catch(error => {
            const data = {
              status: false,
              msg: error.response.data.error,
            }
            this.$emit('step-completed', data)
          })
      }
    },
    getData() {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataUtama = res.data.data

            this.useKrisna = false
            this.saktiUnit.map(dt => {
              if (dt.label === this.dataUtama.unit_kerja) {
                this.useKrisna = true
                this.unit_kerja = dt
              }
              return true
            })

            this.unit_kerja = this.dataUtama.unit_kerja
            this.nama_kegiatan = this.dataUtama.nama_kegiatan
            this.kode_model_referensi = this.dataUtama.kode_model_referensi
            this.rincian_output = this.dataUtama.rincian_output

            this.unit_kerja_es2 = this.dataUtama.unit_kerja
            this.nama_kegiatan_sendiri = this.dataUtama.nama_kegiatan
            this.nama_kode_model_referensi = this.dataUtama.kode_model_referensi
            this.rincian_output = this.dataUtama.rincian_output

            this.pic_name = this.dataUtama.pic
            this.pic_number = this.dataUtama.pic_number

            this.penanggungJawabModel = {
              pic_id: this.dataUtama.pic_id,
              label: `${this.dataUtama.pic} (${this.dataUtama.pic_number})`,
              name: this.dataUtama.pic,
              number: this.dataUtama.pic_number,
            }
            this.prioritas_nasional = this.daftarNationalPriority[this.dataUtama.prioritas_nasional]
            this.total_anggaran = this.dataUtama.total_anggaran
            this.kode_anggaran = this.dataUtama.kode_anggaran

            this.master_jenis_belanja_id = {
              jenis_belanja_id: this.dataUtama.jenis_belanja_id,
              nama: this.dataUtama.jenis_belanja,
            }
            if (this.jenis_pengadaan_id === this.dataUtama.jenis_pengadaan_id) {
              this.master_jenis_pengadaan_id = {
                jenis_pengadaan_id: this.dataUtama.jenis_pengadaan_id,
                nama: this.dataUtama.jenis_pengadaan,
              }
            }
            this.master_sumber_dana_id = {
              sumber_dana_id: this.dataUtama.sumber_dana_id,
              nama: this.dataUtama.sumber_dana,
            }
          }
        })
    },
  },
}
</script>
